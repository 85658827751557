<template>
  <div id="unitsContainer">
    <!--    {{statisticalReportsData}}-->
    <div v-if="statisticalReportsData">

      <vs-tabs>
        <div v-for="(item, idx) in statisticalReportsData">
          <vs-tab :label="item.year" icon="" v-model="selectedYearIndex" color="success" @click="() => { selectedYear = item.year; selectedYearIndex = idx;}"></vs-tab>
        </div>
      </vs-tabs>


      <vs-table :data="getReportData()" @selected="showReport">
        <template slot="header">
          <vs-navbar class="nabarx">
            <div slot="title">
              <vs-navbar-title class="py-4">
                Optia 1000
              </vs-navbar-title>
            </div>
          </vs-navbar>
        </template>
        <template slot="thead">
          <vs-th>
            Enota
          </vs-th>
          <vs-th>
            Naslov
          </vs-th>
          <vs-th>
            Od
          </vs-th>
          <vs-th>
            Do
          </vs-th>
          <vs-th>
            Tip poročila
          </vs-th>
          <vs-th>
            Opombe
          </vs-th>
          <vs-th>
            Akcije
          </vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :key="index" v-for="(tr, index) in data" :data="tr">

            <vs-td :data="data[index].unit">
              <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
                {{data[index].unit.name}}
              </vs-col>
            </vs-td>

            <vs-td :data="data[index].title">
              <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
                {{data[index].title}}
              </vs-col>
            </vs-td>

            <vs-td :data="data[index].start_date">
              <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
                {{moment(data[index].start_date).format('DD. MM. YYYY')}}
              </vs-col>
            </vs-td>

            <vs-td :data="data[index].end_date">
              <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
                {{moment(data[index].end_date).format('DD. MM. YYYY')}}
              </vs-col>
            </vs-td>

            <vs-td :data="data[index].template">
              <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
                {{data[index].template === "0" ? 'SPD1KŽ' : 'Pročilo za občine'}}
              </vs-col>
            </vs-td>

            <vs-td :data="data[index].comment">
              <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
                <vs-tooltip :text="data[index].comment" title="Opomba" :key="data[index].id"
                            v-if="data[index].comment" position="left">
                  <vs-avatar icon="note"/>
                  <span>&nbsp;</span>
                </vs-tooltip>
              </vs-col>
            </vs-td>

            <vs-td :data="data[index].id">
              <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12">
                <vs-button color="primary" icon="cloud_download" size="small" @click.stop="downloadReport(data[index])" class="mx-1">
                  Prenesi
                </vs-button>
                <vs-button color="danger" icon="delete" size="small" @click.stop="beforeDeleteReport(data[index])" class="mx-1">
                  Izbriši
                </vs-button>
              </vs-col>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>

<!--      {{statisticalReportsData}}-->
    </div>

    <vs-row>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12" class="mt-20" style="background-color: #fff;">
        <img :src="require('@/assets/images/content/other/optia_logos.jpg')" class="bgImg" style="max-height: 100px;">
      </vs-col>
    </vs-row>

    <div id="parentx">
      <vs-sidebar position-right parent="body" default-index="1" color="primary" class="unitSidebar" spacer
                  v-model="sidebarActive">

        <Unit v-if="unitId && sidebarActive" :unitId="unitId" />
      </vs-sidebar>
    </div>

    <vs-button radius size="large" color="success" icon-pack="feather" icon="icon-plus-circle" id="addFabBtn"
               title="Dodaj novo poročilo" @click.stop="addReport()"></vs-button>

  </div>
</template>

<script>
  import Vue from 'vue';
  import Unit from "../../components/Sopotniki/units/Unit";


  export default {
    name: 'Optia1000',

    components: {
      Unit
    },

    data() {
      return {
        statisticalReportsData: null,
        unitId: null,
        sidebarActive: false,
        selectedYear: this.moment().format('YYYY'),
        selectedYearIndex: 0
      }
    },

    computed: {

    },

    mounted() {
      const _this = this;
      _this.getStatisticalReports();
    },

    // beforeRouteLeave(to, from, next) {
      // console.log("beforeRouteLeave");
      // const _this = this;
      // _this.unitId = null;
      // _this.sidebarActive = false;
      // setTimeout(() => {next()}, 100);
    //},

    methods: {
      getReportData() {
        const _this = this;

        const statisticalReportsForSelectedYear = _.filter(_this.statisticalReportsData, {year: _this.selectedYear})[0];
        _this.selectedYearIndex = statisticalReportsForSelectedYear ? _.findIndex(_this.statisticalReportsData, function(o) { return o.year ==  _this.selectedYear; }) : 0;

        return statisticalReportsForSelectedYear ? statisticalReportsForSelectedYear.data : null;
      },

      getStatisticalReports() {
        const _this = this;

        _this.$vs.loading();


        Vue.prototype.$http.get(Vue.prototype.$config.api.sopotnikiVPS + 'statistical-report')
          .then((res) => {

            _this.statisticalReportsData = _.chain(res.data.data)
              .groupBy((val) => _this.moment(val.start_date).format('YYYY'))
              .map((value, key) => ({year: key, data: value}))
              .reverse()
              .value();

            console.log("TOLE: ",  _this.statisticalReportsData);

            _this.$vs.loading.close();


          })
          .catch((error) => {
            console.log("error", error);
            _this.$vs.loading.close();
            return false
          });
      },

      showReport(tr) {
        const _this = this;

        console.log("HERE!", tr);

        _this.$router.push({name: 'StatisticalReportEdit', params: {statisticalReportId: tr.id}});
      },

      getUpnUrl (unit) {
        return require('@/assets/images/content/upn/upn_' + unit + '.pdf');
      },

      downloadReport(tr) {
        const _this = this;

        _this.$vs.loading();

        Vue.prototype.$http.get('https://pdf.sopotniki.org/html-to-pdf/' + encodeURIComponent('https://admin-2-0.sopotniki.org/pdf-statistical-report/' + tr.id + '?jwt=' + JSON.parse(localStorage.getItem("userInfo")).token),
          {
            headers: {'secret': 'sopotniki-it'}, responseType: 'blob'
          }
        )
          .then((res) => {
            const file = new Blob(
              [res.data],
              {type: 'application/pdf'});

            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(file)
            link.download = `statisticno_porocilo.pdf`
            link.click();
            _this.$vs.loading.close();
          });
        //
        // let link = document.createElement('a');
        // link.href = _this.getUpnUrl(tr.name.toLowerCase());
        // console.log(link.href);
        // link.download = 'upn_' + tr.name.toLowerCase() + '.pdf';
        // link.dispatchEvent(new MouseEvent('click'));
      },

      addReport() {
        const _this = this;

        _this.$router.push({name: 'StatisticalReportEdit'});
      },

      beforeDeleteReport(report) {
        const _this = this;

        Vue.$confirm({
          title: 'Ste prepričani, da želite izbrisati statistično poročilo z naslovom ' + report.title + '?',
          message: 'Po potrditvi bo dnevno poročilo trajno izbrisano.',
          button: {
            yes: 'Izbriši',
            no: 'Prekliči'
          },
          callback: confirm => {
            console.log("confirm", confirm);
            if (confirm) {
              _this.deleteReport(report);
            }
          }
        });
      },

      async deleteReport(report) {
        const _this = this;

        _this.$vs.loading();

        await Vue.prototype.$http.delete(`${Vue.prototype.$config.api.sopotnikiVPS}statistical-report/${report.id}`)
          .then((res) => {

            console.log('dailyReport deleted');
            _this.$vs.loading.close();
            _this.getStatisticalReports();

          })
          .catch((error) => {
            console.log('error', error);
            _this.$vs.loading.close();
            return false
          });

      },

    },
  }
</script>

